*,
html * /* override x.xhtml.ru style */
{
  scrollbar-width: auto;
  scrollbar-color: #0c5891 #e3eaf0;
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
  height: 12px;
  width: 6px;
}
*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
  background: #e3eaf0;
  padding: 0;
}
*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
  background-color: #0c5891;
  border-radius: 5px;
}

body {
  min-height: 100vh;
}
#root {
  min-height: 100vh;
}
.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.MuiInput-underline:after{
  border-bottom: 0;
}
.MuiInputBase-root{
  box-shadow: none;
}
a{
  color:#000;
}
.MuiToolbar-regular{
  min-height: auto;
}
h2{
  color: #304156;
}
#root .MuiTypography-h3{
  font-size: 2rem;
}

.MuiDataGrid-row{
  color: #828d99;
}
.MuiDataGrid-row, .MuiDataGrid-row .MuiDataGrid-cell{
  line-height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}
.MuiDataGrid-row:nth-child(odd) {
  background-color: #fff;
}
.MuiDataGrid-row:nth-child(even) {
  background-color: #fafbfb;
  border-radius: 2px;
}


.wrapperDropzoneArea {
  text-align: center;

  padding: 24px;
  box-shadow: 0 3px 12px 0 rgba(25, 42, 70, 0.13);
  border: solid 1px #dadada;
  background-color: #fff;
  color:#828d99;
  overflow: hidden;
  position: relative;
}
.dropzonePhotoUserNew .MuiDropzoneArea-textContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  flexShrink: 0;
  width: 100%;
  height: 241px;
  padding: 28px 16px 10px;
}

.dropzonePhotoUserNew .MuiDropzoneArea-textContainer:hover svg {
  color: #fff;
}

.dropzonePhotoUserNew .MuiDropzoneArea-textContainer:after {
  top: 38px;
  content: '';
  z-index: 1;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 2px solid #117dce;
  margin: auto;
  min-height: 100px;
  background-color: #fff;
  transition: background-color 300ms ease-out;
}
.dropzonePhotoUserNew .MuiDropzoneArea-textContainer:hover:after {
  background-color: #117dce;
}

.dropzonePhotoUserNew svg {
  color:#117dce;
  z-index: 10;
  transition: color 300ms ease-out;
}

.dropzonePhotoUserNew p {
  z-index: 10;
  font-size: 1em;
  margin-top: 36px;
  margin-bottom: 12px;
  color: #828d99;
}

.dropzonePhotoUserNew {
  width: auto !important;
  min-height: 241px !important;
  min-width: 207px !important;
  border: none !important;
}

.changeImage {
  position: absolute !important;
  left: calc(50% - 24px);
  bottom: calc(50% - 24px);
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: #828d99 !important;
}

.changeImage:hover {
  background-color: #00978C !important;
  color: #fff !important;
}

.usercabinet .MuiContainer-maxWidthLg {
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#root .dashboardPage.MuiContainer-maxWidthLg{
  max-width: 2560px !important;
}

.MuiDataGrid-root{
  border: 0 !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
  color:#828d99;
  font-size: 12px;
  font-weight: 300 !important;
  display: block !important;
  line-height: 18px;
}
.MuiChip-root{
  height: 24px !important;
  font-size: 11px !important;
  width: 105px;
  border-radius: 6px !important;
}
.MuiChip-root .MuiAvatar-circular{
  color: white;
}
.MuiChip-root.active .MuiAvatar-circular{
  background-color:#72b52e;
}
.MuiChip-root.deactive .MuiAvatar-circular{
  background-color:#f65652;
}
.MuiChip-root .MuiChip-avatar {
  color: #616161;
  width: 12px !important;
  height: 12px !important;
  font-size: 1px !important;
  margin-left: 5px;
  margin-right: -6px;
}
.MuiChip-root .MuiChip-avatar .MuiSvgIcon-root{
  display: none;
}
.MuiToolbar-regular {
  min-height: 56px !important;
}
@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 56px !important;
  }
}

.MuiDataGrid-root .MuiDataGrid-cell{
  border-bottom:0px !important;
}
.MuiDataGrid-row{
  border: solid 0px #fff;
}
.MuiDataGrid-row:hover{
  border-radius: 6px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 0px #fff;
  background-color: #e3eaf0;
  cursor: pointer;
}
.MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer{
  width: 100%;
}
#root .MuiPaper-root .buttonSuccess{
  background-color: #81b346 !important;
  color: white !important;
  line-height: 40px !important;
}
.woFilterHeader .MuiDataGrid-menuIcon{
  /*display: none !important;*/
}
.woFilterHeader .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  width: 100%;
  text-transform: uppercase;
  font-weight: 300;
}
.MuiPaper-elevation4{
  box-shadow: none !important;
  transition:none !important;
}
.usercabinet header .MainLayout-topMenu-124 a{

}
.MuiTablePagination-selectRoot {
  border: solid #dfe3e5 1px;
  border-radius: 5px;
}
.MuiDataGrid-footerContainer div:empty{
  display: none !important;
}
.MuiDataGrid-selectedRowCount{
  display: none !important;
}
#user-profile-popover .MuiPaper-elevation8{
  top: 50px !important;
  margin-left: 70px;
}
#user-profile-popover  .MuiPaper-elevation8{

  border: 1px solid #dfe3e5;
}
#user-profile-popover .MuiPaper-elevation8:after, #user-profile-popover .MuiPaper-elevation8:before {
  top: -10px;
  right: 0%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  margin-top: -10px;
}

#user-profile-popover .MuiPaper-elevation8:after {
  border-color: #dfe3e5;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
#user-profile-popover .MuiPaper-elevation8:before {
  border-color: #dfe3e5;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
  margin-top: -10px;
}
.wrapperTopMobileMenu{
  display: none;
}
@media (max-width: 480px){
  .selectlanguage{
    width: 64px !important;
    margin-right: 0 !important;
  }
  .MuiSelect-select b, .buttonregistr, .wrapperTopMenu,.wrapperUserPanelShort .userName{
    display: none !important;
  }
  .loginform{
    padding: 10px !important;
    margin: 0px !important;
  }
  .loginform h2{
    font-size: 2rem !important;
  }
  .loginform p{
    line-height: 1.4em;
  }
  #user-profile-popover .MuiPaper-elevation8 {
    /*margin-left: -60px !important;*/
  }
  .wrapperTopMobileMenu{
    display: block;
  }
}
header nav{
  margin-left: -16px;
  margin-right: -16px;
}
header nav label{
  margin-left: 16px;
}
header nav label:hover ul{
  display: block;
}
header nav input{
  display: none;
}
header nav ul{
  width: 100%;
  margin-left: 0;
  display: none;
}
header nav ul li:first-child{
  border-top: solid #CCC 1px;
}
header nav ul li{
  border-bottom: solid #CCC 1px;
}
header nav ul li a{
  display: block;
  padding: 20px 0 20px 16px;
}
header nav ul li a:hover{
  opacity: 0.5;
}
.MuiDataGrid-root .MuiDataGrid-iconButtonContainer{
  display: none !important;
}
.MuiDropzoneArea-icon{
  font-size: 6em !important;
}
.MuiPaginationItem-outlinedPrimary.Mui-selected{
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
.MuiDataGrid-columnHeaderWrapper span{
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
  border-bottom: dashed 1px #828d99;
  position: relative;
}
.MuiDataGrid-columnHeaderWrapper span svg{
  font-size: 1.2em;
  position: absolute;
  right: 0;
  top: 0;
}

.MuiDataGrid-columnHeaderWrapper input{

}
.MuiDataGrid-columnHeaderWrapper .MuiDataGrid-columnHeaderDraggableContainer{
  padding-bottom: 3px;
  /*border-bottom: solid #a1b3c6 1px;*/

}
.MuiDataGrid-columnHeaderWrapper .MuiDataGrid-columnSeparator,.MuiDataGrid-columnHeaderWrapper .MuiDataGrid-menuIcon{
  display: none !important;
}
.MuiDataGrid-columnHeaderWrapper .input{
  position: relative;
  /*margin-top: 2px;*/
  border: solid #a1b3c6 1px;
  border-radius: 4px;
  background-color: white !important;
  padding: 2px;
}
.MuiDataGrid-columnHeaderWrapper .input:empty{
  border: 0;
  min-height: 29px;
}

.MuiDataGrid-columnHeaderWrapper .input input {
  padding: 3px;
}
.MuiDataGrid-columnHeaderWrapper .input input:placeholder-shown + label{
  display: block;
  cursor: text;
}

.MuiDataGrid-columnHeaderWrapper .input label{
  position: absolute;
  display: none;
  left: 5px;
  top: 0px;
  color: #304156;
  font-size: 13px;
}
.MuiDataGrid-columnHeaderWrapper .input input + label {
  top: 5px;
}
.input .MuiSelect-select{
  border:0px;
  color: #304156;
  font-size: 14px;
  font-weight: 300 !important;
}
.input .MuiInputBase-input{
  padding: 3px 0px 3px 0px;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
  padding: 0;
}
.MuiDataGrid-main .MuiDataGrid-columnsContainer{
  border-bottom: 0 !important;
}
.userList .MuiDataGrid-columnHeaderTitleContainer *{
  background-color: transparent;
}
.underlineselect .MuiSelect-icon{
  font-size: 1rem !important;
  margin-top: 5px;
}
.userList .MuiDataGrid-row:nth-child(odd) {
   background-color: #f7f9fb;
 }
.userList .MuiDataGrid-row:nth-child(even) {
  background-color: #fff;
}
.userList .MuiDataGrid-window{
  margin-top: 10px;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: inherit !important;
}
.MuiDataGrid-columnHeaderWrapper .input input{
  width: 100%;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
  padding-left: 0px !important;
}
.MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderCheckbox *, .MuiDataGrid-columnHeaderDraggableContainer{
  display: none;
}

@media (min-width: 1600px) {
  .usercabinet .wrapperSelectLang{
    width: 100px !important;
    max-width: 100px !important;
    flex-basis: 100px !important;
    float: right;
    position: absolute;
    right:24px;
  }
  .usercabinet .wrapperUserPanelShort{
   /* padding-right: 110px;*/
    position: absolute;
    right: 134px;
  }
  .usercabinet header .MuiGrid-grid-lg-2{
    /*max-width: 8.666667%;
    flex-basis: 8.666667%;*/
  }
}
.pull-right{
  float: right;
}
